<template>
  <div class="table_wrapper">
    <v-expand-transition>
      <v-alert type="success" v-show="copystatu">
        Copied Success!
      </v-alert>
    </v-expand-transition>
    <v-data-table
      :headers="headers"
      :items="desserts"
      sort-by="c1"
      :loading="loading"
      loading-text="Loading... Please wait"
      class="elevation-1"
      item-key="id"
      :search="search"
      :items-per-page="perPage"
      :options.sync="options"
      :server-items-length="totalDesserts"
    >
      <template v-slot:top>
        <v-toolbar flat>
          <v-toolbar-title>Codes</v-toolbar-title>
          <v-dialog transition="dialog-top-transition" max-width="600">
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                color="primary"
                small
                class="ml-2"
                dark
                v-bind="attrs"
                v-on="on"
                >Create New Code</v-btn
              >
            </template>
            <template v-slot:default="dialogCode">
              <v-card>
                <v-toolbar color="primary" dark>New Code</v-toolbar>
                <v-card-text>
                  <v-row>
                    <v-col cols="12">
                      <v-radio-group v-model="newcode.payment_type" row>
                        <v-radio label="Cash" value="cash"></v-radio>
                        <v-radio label="Wire" value="wire"></v-radio>
                      </v-radio-group>
                    </v-col>
                    <v-col cols="12">
                      <v-text-field
                        label="Recivied By or Confirmation"
                        v-model="newcode.payer"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12">
                      <v-text-field
                        label="Description"
                        v-model="newcode.description"
                      ></v-text-field>
                    </v-col>
                  </v-row>
                </v-card-text>
                <v-card-actions class="justify-end">
                  <v-btn text @click="dialogCode.value = false">Close</v-btn>
                  <v-btn
                    text
                    @click="createNewCode(dialogCode)"
                    :disabled="newcode.payment_type == ''"
                    >Save</v-btn
                  >
                </v-card-actions>
              </v-card>
            </template>
          </v-dialog>
          <v-btn
            color="primary"
            small
            class="ml-2"
            dark
            v-on:click="getDataFromApi(0)"
            >Refresh List</v-btn
          >

          <v-spacer></v-spacer>

          <v-btn
            small
            color="black"
            dark
            class="ml-2"
            v-on:click="selectedAction('delete')"
            v-if="selected.length > 0"
          >
            <v-icon color="white">
              mdi-close
            </v-icon>
            Delete Selected ({{ selected.length }})
          </v-btn>
          <v-spacer></v-spacer>
          <v-text-field
            v-model="search"
            v-on:keyup="tableSearch"
            append-icon="mdi-magnify"
            label="Search"
            single-line
            hide-details
          ></v-text-field>
        </v-toolbar>
      </template>

      <template v-slot:[`item.c2`]="{ item }">
        <v-chip
          :color="item.new ? 'light-blue' : 'primary'"
          dark
          close
          close-icon="mdi-content-copy"
          @click:close="copycode(item.c2)"
        >
          {{ item.c2 }}
        </v-chip>
      </template>

      <template v-slot:[`item.c3`]="{ item }">
        <v-chip :color="item.c3 == 'pending' ? 'success' : 'red'" dark small>
          {{ item.c3 }}
        </v-chip>
      </template>

      <template v-slot:[`item.c4`]="{ item }">
        <v-chip label :color="item.c4 == 'cash' ? 'orange' : 'purple'" dark>
          {{ item.c4 }}
        </v-chip>
      </template>
    </v-data-table>

    <v-dialog v-model="dialog" persistent max-width="290">
      <v-card>
        <v-card-title class="text-h5">
          Warning!
        </v-card-title>
        <v-card-text>Are you sure of what you're doing?</v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="red darken-1" text @click="dialog = false">
            No
          </v-btn>
          <v-btn color="green" text @click="dialogActionYes">
            Yes
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <input type="hidden" id="testing-code" :value="setcode" />
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import axios from "axios";
import moment from "moment";
export default {
  name: "Coupon",
  props: {
    member_id: {
      required: false,
    },
  },
  data() {
    return {
      newcode: {
        payer: "",
        payment_type: "",
        description: "",
        api_token: "",
      },
      tout: null,
      dialogCode: false,
      selected: [],
      setcode: "",
      copystatu: false,
      dialog: false,
      moment: moment,
      actionLock: false,
      options: {},
      search: "",
      activeTab: 0,
      loading: true,
      token: localStorage.getItem("authToken"),
      headers: [
        { text: "ID", value: "c1", sortable: false },
        { text: "Code", value: "c2", sortable: false },
        { text: "Status", value: "c3", sortable: false },
        { text: "Type", value: "c4", sortable: false },
        { text: "Payer", value: "c5", sortable: false },
        { text: "Description", value: "c6", sortable: false },
        { text: "Created By", value: "c7", sortable: false },
        { text: "Created at", value: "c8", sortable: false },
      ],
      desserts: [],
      totalDesserts: 0,
      page: 1,
      perPage: 5,
      itemsData: [],
      mfields: [],
      timer: null,
      dialogAction: {
        url: "",
        data: null,
        type: "",
      },
    };
  },

  watch: {
    options: {
      handler() {
        this.getDataFromApi(this.activeTab);
      },
      deep: true,
    },
  },
  methods: {
    createNewCode(item) {
      item.value = false;
      this.newcode.api_token = this.token;
      axios
        .post(process.env.VUE_APP_API_URL + "code/save", this.newcode)
        .then((res) => {
          if (res.data.status == "success") {
            try {
              const item = res.data.data;
              const keyNew = {
                c1: item.id,
                c2: item.code,
                c3: item.status,
                c4: item.payment_type,
                c5: item.payer,
                c6: item.description,
                c7: item.created_by?.name || "",
                c8: item.created_at,
                item: item,
                id: item.id,
                new: true,
              };
              this.desserts.unshift(keyNew);
            } catch (error) {
              console.log(error);
            }
          }
        })
        .catch((err) => console.log(err));
    },
    copycode: function(code) {
      this.setcode = code;
      this.copystatu = true;

      setTimeout(() => {
        let testingCodeToCopy = document.querySelector("#testing-code");
        testingCodeToCopy.setAttribute("type", "text"); // 不是 hidden 才能複製
        testingCodeToCopy.select();

        try {
          document.execCommand("copy");
        } catch (err) {
          alert("Oops, unable to copy");
        }

        testingCodeToCopy.setAttribute("type", "hidden");
        window.getSelection().removeAllRanges();
      }, 100);

      let th = this;

      if (this.tout) {
        clearInterval(this.tout);
      }
      this.tout = setTimeout(() => {
        th.copystatu = false;
      }, 3000);
    },
    parseLink: function(link) {
      return link.substr(0, 50) + "...";
    },
    parseDateTime: function(date) {
      let dta = new Date(date).getTime();
      return this.moment(dta).fromNow();
    },
    selectedAction: function(type) {
      if (this.selected.length == 0) {
        return false;
      } else {
        const selectedMap = this.selected.map(function(val, index) {
          return val.id;
        });

        let url = "";

        if (type == "delete") {
          url = "https://gowawe.com/api/admin/codes/bulkdelete";
        }

        this.dialog = true;
        this.dialogAction = {
          url: url,
          type: type,
          data: selectedMap,
        };
      }
    },
    dialogActionYes: function() {
      if (this.dialogAction.data == null) {
        return false;
      }
      this.dialog = false;
      this.selected = [];

      if (this.dialogAction.type == "delete") {
        this.desserts = this.desserts.filter((item, index) => {
          return this.dialogAction.data.indexOf(item.id) == -1;
        });
      }

      axios
        .post(this.dialogAction.url, {
          id: this.dialogAction.data,
          api_token: this.token,
        })
        .then((res) => {
          if (
            this.dialogAction.type == "approve" ||
            this.dialogAction.type == "reject"
          ) {
            this.getDataFromApi(this.activeTab);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    getRefreshData: function() {
      this.selected = [];
      this.getDataFromApi(this.activeTab);
    },
    tableSearch: function() {
      if (this.timer) {
        clearTimeout(this.timer);
        this.timer = null;
      }
      this.timer = setTimeout(() => {
        this.getDataFromApi(this.activeTab);
      }, 800);
    },
    getDataFromApi(a) {
      this.activeTab = a;
      this.loading = true;
      this.fakeApiCall().then((data) => {
        this.desserts = [];
        data.itemsR.forEach((item, index) => {
          const key = {
            c1: item.id,
            c2: item.code,
            c3: item.status,
            c4: item.payment_type,
            c5: item.payer,
            c6: item.description,
            c7: item.created_by?.name || "",
            c8: item.created_at,
            item: item,
            id: item.id,
          };
          this.desserts.push(key);
        });

        this.totalDesserts = data.total;
        this.loading = false;
        this.actionLock = false;
      });
    },
    fakeApiCall() {
      return new Promise((resolve, reject) => {
        const { sortBy, sortDesc, page, itemsPerPage } = this.options;

        this.page = page;
        this.perPage = itemsPerPage == -1 ? this.totalDesserts : itemsPerPage;

        this.getDesserts().then((res) => {
          this.itemsData = res.data;

          const total = res.total;

          let itemsR = this.itemsData;

          setTimeout(() => {
            resolve({
              itemsR,
              total,
            });
          }, 1000);
        });
      });
    },
    getDesserts() {
      return axios
        .get(process.env.VUE_APP_API_URL + "code/list?api_token=" + this.token)
        .then(function(response) {
          return response.data.data;
        })
        .catch(function(error) {
          console.log(error);
        });
    },
  },
  mounted() {},
  computed: {
    ...mapGetters("auth", ["user"]),
  },
};
</script>
